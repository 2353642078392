<template>
  <form class="test-avatar-form" @submit.stop.prevent="handleSubmit">
    <b-row class="text-center">
      <b-col>
        <div class="avatar-wrapper">
          <Avatar :is-url="Boolean(preview)" :size="64" :photo="preview ? preview : form.photo" />
          <span class="edit-icon" @click="showModal=true">
            <i class="fas fa-pen"></i>
          </span>
        </div>
      </b-col>
    </b-row>
    <input v-show="false" id="file" ref="file" type="file" accept="image/*" class="d-none" @change="handleUploadAvatar" />
    <div v-if="showModal" class="modal fade show d-block pt-5" :id="'AvatarForm'+userId" tabindex="-1" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Edit User Avatar</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeModal()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <b-row class="text-center">
              <b-col>
                <Avatar :is-url="Boolean(preview)" :size="128" :photo="preview ? preview : form.photo" />
              </b-col>
            </b-row>
          </div>
          <div class="modal-footer">
              <div v-if="preview">
                <FormButton title="Save" variant="outline-primary" :loading="isLoading" />
              </div>
              <div v-else-if="form.photo">
                  <button type="button" class="btn btn-outline-danger mr-3"  @click="handleDeleteAvatar">Delete</button>
                  <button class="btn btn-outline-primary" @click="handleChangeAvatar"> Change </button>
              </div>

              <div v-else align-h="center">
                  <button class="btn btn-outline-primary" @click="handleChangeAvatar"> Add Picture </button>
              </div>
            </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="modal-backdrop show" style="opacity: 0.5;"></div>
  </form>
</template>

<script>
import api from '@/api';
import Avatar from '../avatar/Avatar';
import FormButton from './FormButton';

export default {
  name: 'AvatarForm',
  components: {
    Avatar,
    FormButton,
  },
  props: {
    avatarForm: {
      type: Object,
      required: true,
    },
    userId: {
      type: [String, Number],
      required: true,
    },
    onFinishSubmitting: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      preview: null,
      isLoading: false,
      form: {
        photo: null,
        file: null,
        username: ''
      },
      showModal: false
    };
  },
  watch: {
    avatarForm: {
      handler: function (newVal) {
        this.form = { ...this.form, ...newVal };
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    closeModal(){
      this.handleClearFile()
      this.showModal = false
    },
    async handleSubmit() {
      const { form, userId } = this;

      if (!form.file) {
        console.warn('No file selected.');
        return;
      }

      const data = new FormData();
      const fileName = `${userId}-${form.file.name}`;

      data.append('photoFile', form.file, form.file.name);
      data.append('username', form.username || '');

      try {
        this.isLoading = true;
        await api.users.id.photo.post(userId, data);
        await this.onFinishSubmitting({ fileName });
        this.handleClearFile();
      } finally {
        this.isLoading = false;
        this.showModal = false;
      }
    },
    async handleDeleteAvatar() {
      const { userId } = this;

      await api.users.id.photo.delete(userId);
      await this.onFinishSubmitting({ fileName: '' });
      this.handleClearFile();
      this.showModal = false;
    },
    handleChangeAvatar() {
      const { $refs } = this;
      if ($refs.file) $refs.file.click();
    },
    handleClearFile() {
      this.$refs.file.value = '';
      this.form = { ...this.form, file: null };
      this.preview = null;
    },
    handleUploadAvatar() {
      const self = this;
      const reader = new FileReader();

      this.form = { ...this.form, file: this.$refs.file.files[0] };
      reader.onload = function (e) {
        self.preview = e.target.result;
      };
      reader.readAsDataURL(this.form.file);
    },
  },
};
</script>
<style scoped>
.avatar-wrapper {
  position: relative;
  display: inline-block;
}

.edit-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  padding: 4px;
  font-size: 12px;
  cursor: pointer;
  box-shadow: 0 0 3px rgba(0,0,0,0.2);
  transition: background-color 0.2s ease;
  width: 26px;
  height: 26px;
  border: 1px solid #bebebe;
  color: black;
  background: #a5a5a5c7;;
}

</style>
