<template>
  <b-btn :disabled="loading" type="submit" :variant="variant" :size="size" class="test-form-button">
    <b-spinner v-if="loading" /> <i v-if="isIconActive" :class="`${iconClass} mr-1`"></i> {{ title }}
  </b-btn>
</template>

<script>
export default {
  name: 'FormButton',
  props: {
    title: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      required: false,
      default: () => {
        return 'primary';
      },
    },
    size: {
      type: String,
      required: false,
      default: () => {
        return 'md';
      },
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
    iconClass: {
      type: String,
      required: false,
      default: () => 'fas fa-paper-plane',
    },
    isIconActive: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
};
</script>
